import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { ConsentService, executeOnConsent, giveConsent } from '@app/utils/checkConsent';
import CloseIcon from '@mui/icons-material/Close';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
declare global {
  interface Window {
    hsConversationsSettings?: any; // replace `any` with a more specific type if you know the shape
    HubSpotConversations?: any; // same here, replace with a more specific type if needed
    hsConversationsOnReady?: (() => void)[];
  }
}

export function HubSpotChat() {
  const { user } = useUser();
  const {
    navigation: {
      hubspot: { showChat, chatIsOpen },
    },
    setNavigation,
  } = useNavigation();

  const [showDummy, setShowDummy] = useState(true);

  const chatShouldShow = showChat;

  const loadStatus = window.HubSpotConversations?.widget.status()?.loaded || false;

  // Optionally, you can load the HubSpot script dynamically here if not added in index.html
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = '//js-eu1.hs-scripts.com/26849284.js'; // replace with the actual URL to HubSpot's script
  script.async = true;

  useEffect(() => {
    // attach class .nudge to the iframe container
    const container = document.getElementById('hubspot-messages-iframe-container');

    if (container) {
      if (chatIsOpen) {
        container.classList.remove('nudge');
      } else {
        container.classList.add('nudge');
      }
    }
  }, [chatIsOpen]);

  useEffect(() => {
    //disable the chat in development
    // if (import.meta.env.DEV === true) return;
    // This function is called when HubSpot Conversations API is ready
    function onConversationsAPIReady() {
      if (!chatShouldShow) return;

      const { widget, resetAndReloadWidget, on } = window.HubSpotConversations;

      if (widget.status()?.loaded) {
        // resetAndReloadWidget();
        widget.open();
      } else {
        on('widgetLoaded', () => {
          window.HubSpotConversations.widget.open();

          // All this stuff is needed to check if the chat is open or closed, since
          // we cannot directly query the api for that property nor the iframe content.
          // Hence we need to check the height of the iframe and if it is above a certain threshold
          // we assume the chat is open.

          const iframe = document.getElementById('hubspot-conversations-iframe');
          // Function to log the dimensions of the iframe
          function checkDimensions() {
            const rect = iframe?.getBoundingClientRect();
            setNavigation((nav) => void (nav.hubspot.chatIsOpen = (rect?.height || 0) > 200));
          }

          // Check if the ResizeObserver API is available
          if (typeof ResizeObserver !== 'undefined') {
            // Create a ResizeObserver instance
            const resizeObserver = new ResizeObserver((entries) => {
              for (const entry of entries) {
                if (entry.target === iframe) {
                  checkDimensions();
                }
              }
            });

            // Start observing the iframe
            iframe && resizeObserver.observe(iframe);
          } else {
            console.log('ResizeObserver is not supported in your browser.');
          }

          // Initial log of dimensions
          checkDimensions();
        });

        on('widgetClosed', () => {
          setNavigation((nav) => void (nav.hubspot.chatIsOpen = false));
        });

        widget.load();
      }
    }

    window.hsConversationsSettings = { loadImmediately: false };

    if (user?.hubspotIdToken) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: user.email,
        identificationToken: user.hubspotIdToken,
        // inlineEmbedSelector: '#hubspot-chat-container',
      };
    }

    // Check if external API methods are already available
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      // Callbacks can be added to the hsConversationsOnReady on the window object
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }, [user?.hubspotIdToken || '', showDummy, chatShouldShow]);

  useEffect(() => {}, [loadStatus, window.HubSpotConversations]);

  // this makes sure that the script is only executed when the user has given consent
  useEffect(() => {
    executeOnConsent(ConsentService.Hubspot, () => {
      setShowDummy(false);
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    if (!chatShouldShow) {
      if (loadStatus) {
        console.log('🐞 HubspotChat: Removing widget');
        window.HubSpotConversations?.widget.remove();
      }
    }
  }, [chatShouldShow, window.HubSpotConversations, loadStatus]);

  return showDummy && showChat ? <DummyWidget openConsent={chatShouldShow} /> : null;
}

function DummyWidget({ openConsent }: { openConsent: boolean }) {
  // Define the animation properties using useSpring
  const [style, animate] = useSpring(() => ({
    scale: 1, // Initial scale is 1
    config: { tension: 2000, friction: 50 }, // Customize the spring physics here
  }));

  const [showTeaser, setShowTeaser] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    //after 20 seconds show teaser
    const timer = setTimeout(() => {
      setShowTeaser(true);
    }, 20000);
  }, []);

  const handleClick = () => {
    setShowConsent(true);
    setShowTeaser(true);
  };

  useEffect(() => {
    setShowTeaser(openConsent);
    setShowConsent(openConsent);
  }, [openConsent]);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button onClick={handleClick}>
        <animated.div
          className=" h-[60px] w-[60px] bg-primary-root rounded-full shadow-lg flex items-center justify-center"
          style={{
            transform: style.scale.to((scale) => `scale(${scale})`), // Apply the scale transformation
          }}
          onMouseEnter={() => animate({ scale: 1.1 })} // Scale to 110% on hover
          onMouseLeave={() => animate({ scale: 1 })} // Scale back to 100% when not hovered
        >
          <ChatIcon />
        </animated.div>
      </button>
      {showTeaser && <DummyTeaser onClose={() => setShowTeaser(false)} onClick={handleClick} consent={showConsent} />}
    </div>
  );
}

function DummyTeaser({ onClose, onClick, consent }: { onClose: () => void; onClick: () => void; consent: boolean }) {
  const { t } = useTranslation();
  const handleConsent = () => {
    window.hsConversationsSettings = { loadImmediately: true };
    giveConsent(ConsentService.Hubspot);
    const interval = setInterval(() => {
      const widget = window.HubSpotConversations?.widget;
      if (widget?.status()?.loaded) {
        widget.open();
        clearInterval(interval);
      }
    }, 200);
  };

  return (
    <div
      className={cx(
        'absolute bottom-20 min-h-[94px] w-[240px] bg-white right-0 text-sm rounded-sm shadow-md flex flex-col items-center transition-all',
        consent && 'w-[90vw] border-2 md:w-[240px] md:border-none'
      )}
      onClick={onClick}
    >
      <button
        className="absolute right-0 text-gray-400 hover:bg-slate-100 rounded-full p-1 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation(); // This stops the click from bubbling up
          onClose();
        }}
      >
        <CloseIcon />
      </button>

      <img className="absolute h-14 w-14 -top-8" src="/assets/images/dieter_kopf.svg" alt="dieter logo" />
      <div className="pt-6 px-4 pb-4 text-left">
        {consent ? (
          <>
            <span className="">
              <Trans shouldUnescape={true} t={t} i18nKey={'components.hubspotchat.consenttext'}>
                {t('components.hubspotchat.consenttext')}{' '}
                <a href="/privacy" className="text-gray-600 hover:text-gray-500">
                  {t('components.hubspotchat.policy')}
                </a>
              </Trans>
            </span>
            <div className="flex justify-between mt-4">
              <button
                className="text-primary-root underline hover:text-primary-700"
                onClick={(e) => {
                  e.stopPropagation(); // This stops the click from bubbling up
                  onClose();
                }}
              >
                {t('components.ui.alert_dialog.actions.cancel')}
              </button>
              <button
                className="text-primary-root underline hover:text-primary-700"
                onClick={(e) => {
                  e.stopPropagation(); // This stops the click from bubbling up
                  handleConsent();
                  onClose();
                }}
              >
                {t('components.hubspotchat.agree')}
              </button>
            </div>
          </>
        ) : (
          <span>{t('components.hubspotchat.teaser')}</span>
        )}
      </div>
    </div>
  );
}

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      viewBox="0 0 39 37"
      // class="conversations-visitor-open-icon"
    >
      <defs>
        <path
          id="conversations-visitor-open-icon-path-1:r0:"
          d="M31.4824242 24.6256121L31.4824242 0.501369697 0.476266667 0.501369697 0.476266667 24.6256121z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1432 -977) translate(1415.723 959.455)">
          <g transform="translate(17 17)">
            <g transform="translate(6.333 .075)">
              <path
                fill="#ffffff"
                d="M30.594 4.773c-.314-1.943-1.486-3.113-3.374-3.38C27.174 1.382 22.576.5 15.36.5c-7.214 0-11.812.882-11.843.889-1.477.21-2.507.967-3.042 2.192a83.103 83.103 0 019.312-.503c6.994 0 11.647.804 12.33.93 3.079.462 5.22 2.598 5.738 5.728.224 1.02.932 4.606.932 8.887 0 2.292-.206 4.395-.428 6.002 1.22-.516 1.988-1.55 2.23-3.044.008-.037.893-3.814.893-8.415 0-4.6-.885-8.377-.89-8.394"
              ></path>
            </g>
            <g fill="#ffffff" transform="translate(0 5.832)">
              <path d="M31.354 4.473c-.314-1.944-1.487-3.114-3.374-3.382-.046-.01-4.644-.89-11.859-.89-7.214 0-11.813.88-11.843.888-1.903.27-3.075 1.44-3.384 3.363C.884 4.489 0 8.266 0 12.867c0 4.6.884 8.377.889 8.393.314 1.944 1.486 3.114 3.374 3.382.037.007 3.02.578 7.933.801l2.928 5.072a1.151 1.151 0 001.994 0l2.929-5.071c4.913-.224 7.893-.794 7.918-.8 1.902-.27 3.075-1.44 3.384-3.363.01-.037.893-3.814.893-8.414 0-4.601-.884-8.378-.888-8.394"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
